
import { Component, Vue } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import {
  AllJobTitlesQuery,
  ListApplicantsQuery,
  TalentDashboardQuery,
  AppConfigsQuery,
  CreateJobApplicationDeadlineMutation,
  DeleteJobApplicationDeadlineMutation,
  Applicant,
  ArchiveApplicationsMutationMutation,
  ArchiveApplicationsMutation,
} from "@/gql"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import JobRoleSelect from "@/components/widgets/input/JobRoleSelect.vue"
import AppMetricCard from "@/components/talent/AppMetricCard.vue"

@Component({
  components: {
    AppPage,
    JobRoleSelect,
    AppMetricCard,
  },
  apollo: {
    dashboard: {
      query: TalentDashboardQuery,
      manual: true,

      result({ data }) {
        if (data) {
          this.jobApplicationDeadlines = data.jobApplicationDeadlines
          this.jobTitles = data.jobTitles
        }
      },
    },

    appConfigs: {
      query: AppConfigsQuery,
      variables: {
        filter: { group: "talent" },
      },
      result({ data }) {
        if (data) {
          this.configs = data.appConfigs.data
        }
      },
    },
  },
})
export default class Applicants extends Vue {
  readonly query = ListApplicantsQuery
  readonly headers = [
    {
      text: "First Name",
      align: "start",
      sortable: true,
      value: "firstName",
      custom: true,
    },
    {
      text: "Last Name",
      align: "start",
      sortable: true,
      value: "lastName",
      custom: true,
    },
    {
      text: "Contact",
      align: "start",
      sortable: false,
      value: "contact",
      custom: true,
    },

    {
      text: "Date",
      sortable: true,
      value: "createdAt",
      custom: true,
    },
    {
      text: "Status",
      align: "start",
      sortable: false,
      value: "state",
      custom: true,
    },
  ]
  itemActions!: ActionType
  jobApplicationDeadlines: any[] = []

  allJobTitlesQuery = AllJobTitlesQuery
  showAddDeadlineDialog = false
  addApplicationDeadlineForm = {
    jobTitleId: 0,
    date: "",
  }
  applicantId = ""

  showFullTable = true
  showDialog = false
  loading = false

  applicantMetrics = [
    {
      title: "All",
      value: 100,
      change: 25,
      unit: "%",
    },
    {
      title: "Pending",
      value: 37,
      change: -33,
      unit: "%",
    },
    {
      title: "Reviewed",
      value: 54,
      change: 33,
      unit: "%",
    },
    {
      title: "Offered",
      value: 54,
      change: 33,
      unit: "%",
    },
    {
      title: "Hired",
      value: 72,
      change: -25,
      unit: "%",
    },
  ]

  get applicantsTableExportOptions() {
    return {
      headers: [
        {
          text: "First name",
          value: "firstName",
        },
        {
          text: "Last name",
          value: "lastName",
        },
        {
          text: "Phone",
          value: "phoneNumber",
        },
        {
          text: "Email",
          value: "emailAddress",
        },
        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Referred By",
          value: "referrer",
        },
        {
          text: "Job Role",
          value: "jobRole",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Date of Birth",
          value: "dateOfBirth",
        },
        {
          text: "Country",
          value: "country.name",
        },
        {
          text: "Status",
          value: "state",
        },
        {
          text: "Created At",
          value: "createdAt",
        },
      ],
    }
  }

  gotoDetailPage(applicant: Applicant) {
    this.showFullTable = false
    this.$router.push(this.getDetailRoute(applicant))
    this.applicantId = applicant.id.toString()
  }

  getDetailRoute(applicant: Applicant) {
    return {
      name: this.$routes.TalentApplicantsConsultantDetail,
      params: { id: applicant.scatterId.toString() },
    }
  }

  created() {
    this.itemActions = {
      "View applicant": (applicant: Applicant) => {
        this.gotoDetailPage(applicant)
      },

      "Open in new tab": (applicant: Applicant) => {
        let routeData = this.$router.resolve(this.getDetailRoute(applicant))
        window.open(routeData.href, "_blank")
      },
    }
  }

  getStateColor(state: string) {
    switch (state) {
      case "shortlisted":
        return "blue"
      case "rejected":
        return "error"
      case "pending":
        return "amber"
      default:
        return "primary"
    }
  }

  async addApplicationDeadline() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      return
    }

    const { data } = await this.$apollo.mutate({
      mutation: CreateJobApplicationDeadlineMutation,
      variables: this.addApplicationDeadlineForm,
    })
    if (data.createJobApplicationDeadline.error)
      this.addMutationError(data.createJobApplicationDeadline.error)
    else {
      this.addSuccess("Application deadline added")
      this.showAddDeadlineDialog = false
      this.addApplicationDeadlineForm = { jobTitleId: 0, date: "" }
      this.jobApplicationDeadlines.push(data.createJobApplicationDeadline.jobApplicationDeadline)
    }
  }

  async deleteApplicationDeadline(index: number) {
    if (!window.confirm("Delete Deadline?\n\nThis action is not reversible.")) {
      return
    }

    const { data } = await this.$apollo.mutate({
      mutation: DeleteJobApplicationDeadlineMutation,
      variables: { id: this.jobApplicationDeadlines[index].id },
    })
    if (!data.deleteJobApplicationDeadline.error) {
      this.addSuccess("Application deadline removed")
      this.jobApplicationDeadlines.splice(index, 1)
    }
  }

  get actions() {
    return {
      "Archive pending applications": () => {
        this.showDialog = true
      },
    }
  }

  async onArchiveApplications() {
    this.loading = true

    const result = await this.mutate<ArchiveApplicationsMutationMutation>({
      mutation: ArchiveApplicationsMutation,
    })
    if (result.data?.archiveApplications?.error) {
      this.addError(result.data.archiveApplications.error.message)
      return
    }
    this.loading = false
    this.showDialog = false
    this.addSuccess("Applications archived successfully")
    this.$refs.applicationsList && (this.$refs.applicationsList as any).refetchQuery()
  }
}
