var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('AppPage',{attrs:{"title":"Applications"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h4',{staticClass:"font-weight-regular mt-1",staticStyle:{"color":"#8c8c8c"}},[_vm._v(" View all talent pool applications ")])]},proxy:true}])},[_c('section',{staticClass:"applicants-table"},[(false)?_c('div',{staticClass:"py-4 mb-4 d-flex align-center"},_vm._l((_vm.applicantMetrics),function(metric,idx){return _c('AppMetricCard',{key:idx,attrs:{"metric":metric}})}),1):_vm._e(),_c('section',{staticClass:"d-flex justify-between align-center"},[_c('h3',{staticClass:"mt-1 mb-3"},[_vm._v("Applicants")]),_c('div',{staticClass:"d-flex mb-3"},[_c('span',{staticClass:"px-3 py-1 grey--text"},[_vm._v("View")]),_c('square-icon-button',{staticClass:"mr-2",attrs:{"to":{
              name: _vm.$routes.TalentApplications,
            },"iconColor":"white","icon":"la-table","color":"#007cff","title":"Table View"}}),_c('square-icon-button',{attrs:{"to":{
              name: _vm.$routes.TalentApplicationsSplitView,
            },"iconColor":"rgb(88, 88, 88)","icon":"la-columns","title":"Split View"}})],1)]),_c('v-row',[_c('v-col',{attrs:{"cols":9}},[_c('Table',{ref:"applicationsList",staticClass:"applicants-list-table",attrs:{"query":_vm.query,"queryKey":"applicants","headers":_vm.headers,"exportOptions":_vm.applicantsTableExportOptions,"searchFilters":[
              {
                field: 'nameContains',
                label: 'Name',
              },
            ],"listFilters":[
              {
                field: 'state',
                label: 'Show',
                filters: {
                  applying: 'Applying',
                  completed: 'Completed',
                  shortlisted: 'Shortlisted',
                },
              },
            ],"dateFilters":[
              {
                field: 'createdAtDate',
                label: 'Applied',
              },
            ],"actions":_vm.actions},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.formatDate(item.createdAt, "Do MMM YYYY") : "-"))])]}},{key:`item.firstName`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(item.firstName ?? "N/A"))])])]}},{key:`item.lastName`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.lastName ?? "N/A"))])])]}},{key:`item.contact`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-col my-2"},[_c('span',{staticClass:"mb-1"},[_vm._v(_vm._s(item.phone))]),_c('span',{attrs:{"title":item.email}},[_vm._v(_vm._s(_vm.truncate(item.emailAddress, 25)))])])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticClass:"text-capitalize",staticStyle:{"margin-left":"0 !important"},attrs:{"state":item.state.replace('_', ' ')}})]}},{key:`item.score`,fn:function(){return [_vm._v(" 0 ")]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":3}},[_c('v-card',{attrs:{"flat":"","color":"#E2F3E9"}},[_c('v-card-text',{staticStyle:{"color":"#157d15"}},[_c('div',{staticClass:"d-flex flex-col pa-2"},[_c('span',{staticClass:"d-flex align-center mb-3"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#157d15"}},[_vm._v("la-user-clock")]),_c('h3',{},[_vm._v("Applicants Manager")])],1),_c('span',{staticClass:"mb-3",staticStyle:{"color":"#157d15"}},[_vm._v("View, drag and drop applicant between multiple stages.")]),_c('secondary-button',{staticClass:"d-flex mt-8",style:({
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                    color: '#157D15',
                    borderColor: '#157D15 !important',
                  }),attrs:{"to":{
                    name: _vm.$routes.TalentApplicationsSplitView,
                  }}},[_vm._v("View")])],1)])],1)],1)],1)],1),_c('section',{staticClass:"application-deadlines"},[_c('v-row',[_c('v-col',{attrs:{"cols":9}},[_c('div',{staticClass:"mt-8 mb-2 d-flex dealine-title-wrap"},[_c('header2',[_vm._v("Deadlines")]),_c('v-spacer'),_c('SecondaryButton',{attrs:{"text":""},on:{"click":function($event){_vm.showAddDeadlineDialog = true}}},[_vm._v("Add deadline")])],1),_c('v-card',{staticClass:"box-shadow",attrs:{"outlined":""}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Deadline")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.jobApplicationDeadlines),function(deadline,index){return _c('tr',{key:deadline.id},[_c('td',[_vm._v(_vm._s(deadline.jobTitle.name))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(deadline.date, "ddd Do MMMM, YYYY")))]),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteApplicationDeadline(index)}}},[_c('v-icon',[_vm._v("la-trash-alt")])],1)],1)])}),0)])],1)],1)],1)],1),_c('FormDialog',{attrs:{"label":"Add application deadline"},model:{value:(_vm.showAddDeadlineDialog),callback:function ($$v) {_vm.showAddDeadlineDialog=$$v},expression:"showAddDeadlineDialog"}},[_c('validation-observer',{ref:"observer"},[_c('validation-provider',{attrs:{"name":"Role","rules":{
            required: _vm.addApplicationDeadlineForm.jobTitleId,
          },"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('JobRoleSelect',{attrs:{"error-messages":errors,"label":"Job title"},model:{value:(_vm.addApplicationDeadlineForm.jobTitleId),callback:function ($$v) {_vm.$set(_vm.addApplicationDeadlineForm, "jobTitleId", $$v)},expression:"addApplicationDeadlineForm.jobTitleId"}})]}}])}),_c('validation-provider',{attrs:{"name":"Date","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('date-picker',{attrs:{"label":"Deadline date","prependIcon":"","error-messages":errors},model:{value:(_vm.addApplicationDeadlineForm.date),callback:function ($$v) {_vm.$set(_vm.addApplicationDeadlineForm, "date", $$v)},expression:"addApplicationDeadlineForm.date"}})]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.addApplicationDeadline}},[_vm._v("Add")])],1)],1),_c('ConfirmDialog',{attrs:{"loading":_vm.loading},on:{"confirm":_vm.onArchiveApplications},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('div',{staticClass:"message"},[_vm._v(" You're about to archive all pending applications, please note that this action is not reversible. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }